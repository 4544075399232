import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutFR from "../composants/layout/fr/LayoutFR"
import { obtenirImages, obtenirImage, obtenirPage } from "../js/client-es/utils"

import fragmentsImages from "../js/client-es/fragments/fragments-images"
import fragmentsPages from "../js/client-es/fragments/fragments-pages"
import Lien from "../composants/navigation/Lien"
import Image from "../composants/image/Image"
import Section from "../composants/layout/Section"

export const requete = graphql`
  {
    pageAPropos: allPagesCompilationYaml(filter: { code: { eq: "aPropos" } }) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(filter: { code: { in: ["tf1"] } }) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: { code: { in: ["numeriqueResponsable"] } }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["moiMicro", "moi"] } }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function APropos() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageAPropos")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageTresPetit",
    "imagesPortrait"
  )

  return (
    <LayoutFR page={page}>
      <Section
        id="section-numerique-responsable"
        titre="Un site basse consommation pour un numérique plus responsable"
      >
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <p>
              Un message militant pour introduire cette page. Notre boulimie
              pour le numérique n’est pas sans conséquences environnementales.
              En 2025, il devrait représenter <strong>6 %</strong> des émissions
              de CO₂ dans le monde (
              <Lien urlExterne="https://www.greenit.fr/wp-content/uploads/2019/10/2019-10-GREENIT-etude_EENM-rapport-accessible.VF_.pdf">
                source
              </Lien>
              ), soit l’équivalent des émissions d’un pays comme l’Inde.
            </p>
            <p>
              Le numérique et ses objets connectés seront aussi responsables de
              la <strong>destruction de notre ciel étoilé</strong>. L’Internet
              satellitaire engendrera le déploiement de dizaines de milliers de
              satellites dans un avenir très proche qui seront autant de
              nouveaux points brillants dans le ciel nocturne. Le spectacle
              d’une voûte céleste observée aux jumelles vierge de tout satellite
              ne sera bientôt plus qu’un lointain souvenir.
            </p>
            <p>
              Aucun site Internet ne peut être écologique. Toutefois, celui-ci a
              été conçu pour limiter les ressources informatiques nécessaires à
              son fonctionnement afin de réduire son impact environnemental.
            </p>
            <ul>
              <li>
                <strong>Le poids réduit des pages</strong> (696 ko en moyenne,
                soit 3 fois moins que la moyenne mondiale) (
                <Lien urlExterne="https://httparchive.org/reports/page-weight">
                  source
                </Lien>
                ) et le chargement des images uniquement à la demande permet une
                accessibilité rapide partout dans le monde quelle que soit la
                qualité du réseau et quel que soit l’âge de l’ordinateur ou du
                téléphone.
              </li>
              <li>
                <strong>Aucune base de données</strong> n’est utilisée.
              </li>
              <li>
                L’hébergement est réalisé France dans un datacenter efficient,
                sur <strong>un serveur à très faible puissance</strong> et à la
                configuration manuellement optimisée.
              </li>
            </ul>
            <p>
              La responsabilité du site s’applique également aux{" "}
              <strong>données personnelles</strong>. Elles ne servent qu’à
              réaliser un suivi des consultations par page et elles sont
              auto-collectées, auto-hébergées et anonymisées par un logiciel
              pleinement compatible RGPD et open-source (
              <Lien urlExterne="https://fr.matomo.org/">Matomo Analytics</Lien>
              ). Vous pouvez vous exclure des comptages en décochant l’option
              présente sur cette{" "}
              <Lien codePageCible="mentionsLegales">page</Lien>.
            </p>
          </div>
          <Image
            image={obtenirImage(images, "numeriqueResponsable")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={false}
          />
        </div>
      </Section>
      <Section titre="Moi :-)">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <p>
              Je m’appelle Bastien, je suis né en 1982 et j’occupe un poste
              d’ingénieur chez un éditeur de logiciels.
            </p>

            <p>
              J’ai toujours été tête en l’air… dans tous les sens du terme. Mon
              intérêt pour l’astronomie a commencé tout petit avec la comète
              Hyakutake en 1996. Sont ensuite venus divers cadeaux astronomiques
              : un planétarium de chambre, un livre sur les constellations…
            </p>

            <p>
              Puis les évènements astronomiques se sont enchaînés et m’ont
              passionné, jusqu’au jour où je regarde pour la première fois dans
              un télescope. J’y vois Mars qui était alors au plus près de la
              Terre, en 2003. Je ne pensais pas qu’on y verrait autant de choses
              : les calottes polaires, les mers… Je me suis alors acheté mon
              premier télescope. Il était entièrement manuel, j’ai dû apprendre
              toutes les constellations par cœur pour espérer m’en servir.
            </p>

            <p>
              Aussi très attiré par la photo, j’ai vu dans l’astrophotographie
              un moyen de rassembler mes passions. J’ai commencé en 2005 avec
              les premiers réflex numériques et un petit télescope chinois. Les
              premiers essais sont magiques : les nébuleuses apparaissent en
              couleurs, comme sur les photos de Hubble. J’ai ensuite pratiqué
              pendant plus de 12 ans avec mes amis de l’Association Astronomique
              de l’Ain près de Bourg-en-Bresse. Je suis maintenant installé en
              Bretagne dans le Finistère où les trouées nuageuses me permettent
              d’observer le ciel étoilé ;-)
            </p>

            <p>
              L’astrophotographie est une véritable école de patience. Les
              conditions parfaites sont rares. Il faut faire face aux nuages, au
              vent, à l’humidité, au froid, à la fatigue, à la pollution
              lumineuse. Les échecs sont nombreux. Les objets ne sont
              photographiables que 1 à 2 mois par an, et seulement lors de nuits
              sans Lune. Quand les conditions sont réunies, il faut passer une
              ou plusieurs nuits éveillé, à surveiller le matériel qui tourne.
              Certaines images présentées dans cette galerie comptent plus de 70
              heures de poses cumulées.
            </p>

            <p>
              En espérant que mes quelques travaux vous feront devenir, comme
              moi, « tête en l’air » …
            </p>
          </div>
          <Image
            image={obtenirImage(images, "moi")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={true}
          />
        </div>
      </Section>
      <Section titre="Références">
        <div className="conteneur-cote-a-cote">
          <div className="conteneur-cote-a-cote-texte">
            <ul className="liste-avec-espace-entre-items">
              <li>
                01/12/23 : L’image du télescope{" "}
                <Lien codePageCible="voieLacteeTelescopeHESSII">HESS II</Lien>{" "}
                illustre la{" "}
                <Lien urlExterne="https://www.spektrum.de/inhaltsverzeichnis/jubilaeum-20-jahre-h-e-s-s-sterne-und-weltraum-12-2023/2099391">
                  première de couverture
                </Lien>{" "}
                du mois de décembre du magazine allemand « Sterne und Weltraum »
                (« Etoiles et espace »), consacré aux 20 ans du plus gros
                télescope gamma au monde.
              </li>
              <li>
                01/03/22 : L’image du télescope{" "}
                <Lien codePageCible="voieLacteeTelescopeHESSII">HESS II</Lien>{" "}
                illustre la{" "}
                <Lien urlExterne="https://onlinelibrary.wiley.com/toc/15213943/2022/53/2">
                  première de couverture
                </Lien>{" "}
                du mois de mars du magazine de physique allemand « Physik in
                unserer zeit » (« La physique de notre époque »).
              </li>
              <li>
                28/10/21 : L’image{" "}
                <Lien codePageCible="orionZoom">Orion 360</Lien> illustre le
                livre{" "}
                <Lien urlExterne="https://www.amazon.com/Bang-Brian-May/dp/1787398277">
                  Bang!!
                </Lien>
                , rédigé par la rockstar Brian May (guitariste du groupe Queen)
                et Sir Patrick Moore.
              </li>
              <li>05/08/21 : Reportage pour le journal de 20 heures de TF1.</li>
              <li>21/03/21 : Astrobin, image du jour.</li>
              <li>
                25/11/20 :{" "}
                <Lien urlExterne="http://www.museedelaphoto.fr/">
                  Acquisition d’une collection de 9 tirages par le Musée
                  Français de la Photographie
                </Lien>
                .
              </li>
              <li>
                11/09/20 :{" "}
                <Lien urlExterne="https://www.rmg.co.uk/whats-on/astronomy-photographer-year/galleries/2020/stars-and-nebulae">
                  Astrophotographer of the year 2020 : finaliste
                </Lien>
                .
              </li>
              <li>25/04/20 : Astrobin, image du jour.</li>
              <li>
                18/06/18 :{" "}
                <Lien urlExterne="http://planetarynebulae.net/FR/page_np.php?id=76">
                  Découverte de la nébuleuse planétaire candidate FoGl1
                </Lien>
                .
                <ul>
                  <li>
                    <Lien urlExterne="http://planetarynebulae.net/documentation/LEDU 125.pdf">
                      Article publié dans la revue « L’Astronomie » de mars 2019
                    </Lien>
                    , éditée par la Société Astronomique de France.
                  </li>
                  <li>
                    <Lien codePageCible="decouverteNebuleusePlanetaire">
                      Récit de la découverte
                    </Lien>
                    .
                  </li>
                  <li>
                    <Lien urlExterne="http://planetarynebulae.net/tableau_spectres/FoGl_1_fiche.pdf">
                      Analyse spectrale.
                    </Lien>
                  </li>
                </ul>
              </li>
              <li>
                12/12/17 :{" "}
                <Lien urlExterne="http://www.ifa.hawaii.edu/~reipurth/newsletter/newsletter300.pdf">
                  Image de couverture du 300ᵉ numéro (25 ans) de la lettre
                  scientifique « Star formation newsletter »
                </Lien>{" "}
                (Institut d’astronomie de l’Université d’Hawaï).
              </li>
              <li>
                19/11/17 :{" "}
                <Lien urlExterne="http://www.photo-montier.org/fiche-exposant/escursia-3/">
                  Conférence au Festival International de la photo de
                  Montier-en-Der
                </Lien>
                .
              </li>
              <li>
                15/08/17 :{" "}
                <Lien urlExterne="https://apod.nasa.gov/apod/ap170815.html">
                  Astronomy Picture of the Day (NASA)
                </Lien>
                .
              </li>
              <li>
                26/04/15 :{" "}
                <Lien urlExterne="https://photos.cala.asso.fr/displayimage.php?pid=2917">
                  Planétarium de Vaulx-en-Velin, premier prix du concours photos
                  du festival « Oufs d’astro »
                </Lien>
                .
              </li>
              <li>
                2013 - 2015 : Ingénieur logiciel pour le fabricant de caméras
                CCD Atik.
              </li>
            </ul>
          </div>
          <Image
            image={obtenirImage(images, "moiMicro")}
            afficherLegende={false}
            langue={page.langue}
            afficherOmbre={true}
          />
        </div>
        <Image
          image={obtenirImage(images, "tf1")}
          afficherLegende={false}
          langue={page.langue}
          afficherOmbre={true}
        />
      </Section>
      <Section titre="Expositions">
        <h3>2020 - 2018</h3>
        <ul className="liste-avec-espace-entre-items">
          <li>Juillet - Août 2020 : Festival NightScapades (Lourdes, 65).</li>
          <li>Juillet - Août 2019 : Festival NightScapades (Lourdes, 65).</li>
          <li>
            Avril 2019 - Avril 2020 : les Saisons de la Photo en grande Forêt de
            Saint-Hubert (Belgique).
          </li>
          <li>
            Septembre 2018 :{" "}
            <Lien urlExterne="https://www.exposaves.be/portfolio/gloaguen-sabine-foucher-bastien/">
              Festival international AVES de la photo de Namur
            </Lien>{" "}
            (Belgique).
          </li>
          <li>
            Mars 2018 :{" "}
            <Lien urlExterne="https://festivallpn.wixsite.com/lyceephotonature/selection-2018">
              invité d’honneur au Festival Lycée Photo Nature
            </Lien>{" "}
            (Bourg-en-Bresse).
          </li>
        </ul>
        <h3>2017 - 2015</h3>
        <ul className="liste-avec-espace-entre-items">
          <li>
            Novembre 2017 :{" "}
            <Lien urlExterne="https://www.photo-montier.org/fiche-exposant/nos-tetes-etoiles/">
              21ᵉ Festival international de la photo animalière et de nature de
              Montier-en-Der
            </Lien>
            .
          </li>
          <li>
            Avril 2017 :{" "}
            <Lien urlExterne="https://festivalphoto-atoutsud.com/project/edition-2017/">
              Festival Photo Atout Sud
            </Lien>{" "}
            (Nantes).
          </li>
          <li>
            Juillet 2016 : cinquantenaire de l’Association Astronomique de
            l’Ain.
          </li>
          <li>
            Octobre 2015 : Fête de la science, médiathèque de Bourg en Bresse.
          </li>
          <li>Février 2015 : Festival photo de la Pévèle.</li>
        </ul>
      </Section>
    </LayoutFR>
  )
}
